import React from 'react'
import { IBookingTicketDetails } from '../booking-ticket-details-interfaces';
import { ArrowForwardRounded } from '@mui/icons-material';
import { dateUtil } from '../../../../../utility/dateUtil';

interface TripVesselInfoProps {
    bookingTicketDetails: IBookingTicketDetails
}

const TripVesselInfo: React.FC<TripVesselInfoProps> = ({ bookingTicketDetails }) => {
    return (
        <section className="grid grid-cols-1 lg:grid-cols-2 gap-4 border-b pb-6 mb-6">
            <div>
                <h3>Trip Information</h3>
                <table>
                    <tbody>
                        <tr><td>Route:&emsp;</td><td>{bookingTicketDetails.trip.route.ghatFrom.ghatName}
                            <ArrowForwardRounded className='mx-2' fontSize={'small'} />
                            {bookingTicketDetails.trip.route.ghatTo.ghatName}</td></tr>
                        <tr><td>Departure:&emsp;</td><td>{dateUtil.formatDateTime(bookingTicketDetails.trip.tripDate).split('|')[0].trim()}, {bookingTicketDetails.trip.departureTime}</td></tr>
                        <tr><td>Duration:&emsp;</td><td>{bookingTicketDetails.trip.tripDuration}</td></tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h3>Vessel Information</h3>
                <table>
                    <tbody>
                        <tr><td>Vessel Name:&emsp;</td><td>{bookingTicketDetails.trip.vessel.name} ({bookingTicketDetails.trip.vessel.vesselType})</td></tr>
                        <tr><td>Reg No:&emsp;</td><td>{bookingTicketDetails.trip.vessel.regNo}</td></tr>
                        <tr><td>Dock No:&emsp;</td><td>{bookingTicketDetails.trip.dockNo}</td></tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default TripVesselInfo;