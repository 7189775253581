export const UserFormInitialState: IUser = {
    id: null,
    fullName: '',
    username: '',
    enabled: true,
    mobileNo: '',
    email: '',
    password: '',
    confirmPassword: '',
    mpin: '',
    role: '', 
    jurisdiction: '',
    jurisdictionId: 0
}

export interface IUser {
    id: number | null;
    fullName: string;
    username: string;
    enabled: boolean;
    mobileNo: string;
    email: string;
    password: string;
    confirmPassword: string;
    mpin: string;
    role: string;
    jurisdiction: string;
    jurisdictionId: number;
  }