import React from 'react'

export interface TicketItemTableHeader {
    name: string;
    label: string;
}

interface TicketItemDetailsTableProps {
    title: string
    headers: TicketItemTableHeader[]
    tickets: any[]
}

const TicketItemDetailsTable: React.FC<TicketItemDetailsTableProps> = ({ ...props }) => {
    return (
        <section className="mb-6 border-b pb-6">
            <h5>{props.title}</h5>
            <div className='overflow-x-scroll pb-4 md:pb-0'>
                <table className='w-full'>
                    <thead>
                        <tr>
                            {props.headers.map((header, index) => {
                                const textAlignClass = index === 0
                                    ? 'text-left'
                                    : index === props.headers.length - 1
                                        ? 'text-right'
                                        : 'text-left';
                                return (
                                    <th key={header.name} className={`w-auto ${textAlignClass} font-medium px-3`}>
                                        {header.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {props.tickets.map((ticket, rowIndex) => (
                            <tr key={rowIndex}>
                                {props.headers.map((header, colIndex) => {
                                    const textAlignClass = colIndex === 0
                                        ? 'text-left'
                                        : colIndex === props.headers.length - 1
                                            ? 'text-right'
                                            : 'text-left';
                                    const splitHeader = header.name.split('.');
                                    return (
                                        <td key={header.name} className={`w-auto ${textAlignClass} px-3`}>
                                            {splitHeader.length > 1 ?
                                                ticket[splitHeader[0]][splitHeader[1]]
                                                :
                                                ticket[splitHeader[0]]
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default TicketItemDetailsTable;