import React from 'react'
import Card from '../../../../components/cards/Card';
import { ArrowForwardRounded } from '@mui/icons-material';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { dateUtil } from '../../../../utility/dateUtil';
import { BookingDetails, BookingStatus } from './booking-history-interfaces';
import useBookingHistoryService from './useBookingHistoryService';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes-constants';
import { BookingDetailsPost, Goods, Passenger, Vehicle } from '../../ticket-booking/ticket-booking-interfaces';
import { useTicketBookingService } from '../../ticket-booking/useTicketBookingService';

interface BookingDetailsCardProps {
	booking: BookingDetails
}

const BookingDetailsCard: React.FC<BookingDetailsCardProps> = ({ booking }) => {

	const { day: tripDay, month: tripMonth, year: tripYear } = dateUtil.splitDate(booking.trip.tripDate);

	const navigate = useNavigate();
	const { fetchTicketDetails } = useBookingHistoryService();
	const { saveBookingDetails } = useTicketBookingService();


	const handleViewDetailsClick = async (bookingId: string) => {
		const bookingTicketDetails = await fetchTicketDetails(bookingId);
		if (!bookingTicketDetails) {
			toast.error("Booking details not found.");
			return;
		}
		navigate(ROUTES.USER.BOOKING_TICKET_DETAILS, { state: { bookingTicketDetails: bookingTicketDetails } });
	}

	const handleContinueBookingClick = async (bookingId: string, tripId: number) => {
		navigate(ROUTES.USER.TICKET_BOOKING, { state: { bookingId: bookingId, tripId: tripId } })
	}

	const handleProceedToPaymentClick = async () => {

		const passengerFormList: Passenger[] = [];
		const vehicleFormList: Vehicle[] = [];
		const goodsFormList: Goods[] = [];

		booking.passengers.forEach(passenger => {
			const id = dateUtil.getTimestamp().toString();
			passengerFormList.push({
				id: id,
				passengerName: passenger.passengerName,
				mobileNo: passenger.mobileNo,
				email: passenger.email,
				pwd: passenger.pwd,
				gender: passenger.gender,
				age: Number(passenger.age),
				uniqueId: id,
			})
		});

		booking.vehicles.forEach(vehicle => {
			const uniqueId = passengerFormList.find(passenger => passenger.passengerName === vehicle.ownerName)?.uniqueId;
			if (!uniqueId) return;

			vehicleFormList.push({
				id: '',
				model: vehicle.model,
				regNo: vehicle.regNo,
				item: {
					id: vehicle.item.id,
					itemType: vehicle.item.itemType,
				},
				vehicleType: vehicle.item.itemType,
				passengerUniqueId: uniqueId,
				ownerName: vehicle.ownerName,
			});
		});

		booking.goods.forEach(vehicle => {
			goodsFormList.push({
				id: '',
				description: vehicle.description,
				weight: vehicle.weight.toString()
			})
		})

		const bookingDetails: BookingDetailsPost = {
			booking: {
				id: booking.id,
			},
			trip: {
				id: Number(booking.trip.id)
			},
			email: booking.email,
			mobileNo: booking.mobileNo,
			passengers: passengerFormList,
			vehicles: vehicleFormList,
			goods: goodsFormList
		}

		const bookingResponse = await saveBookingDetails(bookingDetails);

		bookingResponse && navigate(ROUTES.USER.ORDER_DETAILS, { state: { bookingResponse: bookingResponse } });
	}

	return (
		<>
			<Card>
				<div>
					{/* Card Header */}
					<div className='flex justify-between items-start mb-4'>
						<span className='text-xl font-semibold text-gray-900'>
							{booking.trip.route.ghatFrom.ghatName} <ArrowForwardRounded /> {booking.trip.route.ghatTo.ghatName}
							<span className='mx-4'>|</span>
							{booking.trip.vessel.name} ({booking.trip.vessel.vesselType})
						</span>
						<span
							className={`px-3 py-1 rounded-full text-sm font-semibold ${booking.bookingStatus === BookingStatus.CONFIRM
								? 'bg-green-100 text-green-600'
								: 'bg-yellow-100 text-yellow-600'
								}`}
						>
							{booking.bookingStatus}
						</span>
					</div>

					<div className='border-t border-gray-200 pt-4'>
						<div className='flex flex-wrap justify-between mb-3'>
							<div className='flex gap-x-4'>
								<div>
									<div className='text-center border-2 border-red-400 rounded'>
										<div className='text-xl font-medium text-gray-800 bg-white'>{tripDay}</div>
										<div className='bg-red-400 text-white'>
											<div className='m-0 px-2'>{tripMonth}</div>
											{/* <h6 className='m-0 leading-none'>{tripYear}</h6> */}
										</div>
									</div>
								</div>

								<div>
									<table><tbody>
										<tr><td>PNR</td><td>: {booking.pnrNo}</td></tr>
										<tr><td>Booking No</td><td>: {booking.bookingNo}</td></tr>
									</tbody></table>
								</div>
							</div>
							<div className='flex w-full md:w-max gap-8 border-t md:border-none pt-3 md:pt-0 mt-3 md:mt-0'>
								{booking.passengers.length > 0 && (
									<p>Passengers:
										<span className='font-medium ml-2'>{booking.passengers.length}</span>
									</p>
								)}
								{booking.vehicles.length > 0 && (
									<p>Vehicles:
										<span className='font-medium ml-2'>{booking.vehicles.length}</span>
									</p>
								)}
								{booking.goods.length > 0 && (
									<p>Goods:
										<span className='font-medium ml-2'>{booking.goods.length}</span>
									</p>
								)}
							</div>
						</div>

					</div>

					<div className='mt-4 border-t pt-4 flex justify-between items-center'>
						<div className='text-gray-600'>
							<p className='text-sm font-medium'>Total Fare</p>
							<p className='text-lg font-semibold text-gray-900'>₹{booking.payment?.netAmount || 0}</p>
						</div>
						<div>
							{
								booking.bookingStatus === BookingStatus.HOLD &&
								<PrimaryButton
									onClick={() => handleContinueBookingClick(booking.id, Number(booking.trip.id))}
									buttonText={'Continue Booking'}
								/>
							}
							{
								booking.bookingStatus === BookingStatus.PENDING &&
								<PrimaryButton
									onClick={() => handleProceedToPaymentClick()}
									buttonText={'Proceed to Payment'}
								/>
							}
							{
								booking.bookingStatus === BookingStatus.CONFIRM &&
								<PrimaryButton
									onClick={() => handleViewDetailsClick(booking.id)}
									buttonText={'View Details'}
								/>
							}
						</div>
					</div>
				</div>
			</Card>
		</>
	)
}

export default BookingDetailsCard;