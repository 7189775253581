const getBaseUrl = (index: number) => {
    switch (index) {
        case 1: return 'https://eticketing-api.bohniman.in';
        case 2: return 'http://13.232.123.30:8080/ETICKETING';
        case 3: return 'https://eticketing-api2.bohniman.in';
        default: return 'https://eticketing-api.bohniman.in';
    }
}

const API_ENDPOINT = {

    BASE_URL: getBaseUrl(3),
    // AUTH
    AUTH: {
        POST_SIGN_UP: `/auth/signup`,
        POST_SIGN_IN: `/auth/signin`,
        POST_RESET_PASSWORD: `/auth/reset-password`,
        POST_RESET_PASSWORD_OTP: `/auth/reset-password-otp`,
        POST_RESEND_OTP: `/auth/resend-otp`,
        POST_REFRESH_TOKEN: `/auth/refreshtoken`,
        POST_SIGN_OUT: `/auth/logout`,
        POST_SEND_OTP: `/auth/get-otp`,
    },

    // COMMON
    COMMON: {
        ENUM: {
            VESSEL_TYPES: `/api/common/vessel-types`,
            UNIT_TYPES: `/api/common/unit-types`,
            TRIP_STATUS: `/api/common/trip-statuses`,
            STATUS_LIST: `/api/common/statuses`,
            SERVICE_TYPES: `/api/common/service-types`,
            SEAT_STATUS: `/api/common/seat-statuses`,
            PWD: `/api/common/pwd`,
            OWNERS: `/api/common/owners`,
            NIGHT_NAVIGATION: `/api/common/night-navigations`,
            MONTHS: `/api/common/months`,
            GENDERS: `/api/common/genders`,
            DEVICE_TYPES: `/api/common/device-types`,
            WEEKDAYS: `/api/common/days-of-week`,
            BOOKING_STATUS: `/api/common/booking-status`,
            BOOKING_MODES: `/api/common/booking-mode`,
            ROLES: `/api/common/roles`,
        },
        MASTER: {
            GET_ALL_ITEMS: `/api/common/get-all-items`,
            GET_ALL_DECKS: `/api/common/get-all-decks`,
            GET_ACTIVE_DIVISIONS: `/api/common/get-active-divisions`,
            GET_ACTIVE_GHATS: `/api/common/get-active-ghats`,
            GET_ACTIVE_GHATS_BY_DIVISION: `/api/common/get-active-ghats-by-division`,
            GET_ACTIVE_GHATS_BY_DIVISION_LIST: `/api/common/get-active-ghats-by-division-list`,
            GET_ACTIVE_ROUTES: `/api/common/get-active-routes`,
            GET_ACTIVE_ROUTES_BY_DIVISION: `/api/common/get-active-routes-by-division`,
            GET_ACTIVE_VESSELS_BY_DIVISION: `/api/common/get-active-vessels-by-division`,
        }
    },

    // MANAGEMENT
    MANAGE: {
        DIVISION: {
            POST_DIVISION: `/api/manage/save-division`,
            GET_DIVISION: `/api/manage/get-divisions`,
        },
        GHAT: {
            SAVE_GHAT: `/api/manage/save-ghat`,
            GET_GHATS_BY_DIVISION: `/api/manage/get-ghats-by-division`,
            GET_GHATS: `/api/manage/get-all-ghats`,
        },
        ROUTE: {
            SAVE_ROUTE: `/api/manage/save-route`,
            GET_ROUTES: `/api/manage/get-all-routes`,
            GET_ROUTES_BY_DIVISION: `/api/manage/get-routes-by-division`,
            GET_ROUTES_BY_GHAT: `/api/manage/get-routes-by-ghat`,
        },
        VESSEL: {
            SAVE_VESSEL: `/api/manage/save-vessel`,
            GET_VESSELS_BY_GHAT: `/api/manage/get-vessels-by-ghat`,
            GET_VESSELS_BY_DIVISION: `/api/manage/get-vessels-by-division`,
            DELETE_VESSEL: `/api/manage/delete-vessel`,
        },
        VESSEL_LAYOUT: {
            SAVE_VESSEL_LAYOUT: `/api/manage/save-vessel-layout`,
            GET_VESSEL_LAYOUT: `/api/manage/get-vessel-layout`,
        },
        TRIP: {
            SAVE_TRIP: `/api/manage/save-trip`,
            GET_TRIPS: `/api/manage/get-trips`,
        },
        TRIP_SETTINGS: {
            SAVE_TRIP_SETTINGS: `/api/manage/save-trip-settings`,
            GET_TRIP_SETTINGS_BY_ROUTE: `/api/manage/get-trip-settings-by-route`,
        },
        RATE: {
            SAVE_ROUTE: `/api/manage/save-rate`,
            GET_RATES_BY_ROUTE: `/api/manage/get-rates-by-route`,
        },
        USER_MANAGEMENT: {
            SAVE_USER: `/api/manage/save-user`
        }
    },

    // GUEST
    PUBLIC: {
        GET_TRIPS: `/api/public/get-trips`,
    },

    // BOOKING
    BOOKING: {
        GET_TRIP: `/api/user/get-trip`,
        INITIATE_BOOKING: `/api/user/initiate-booking`,
        CREATE_BOOKING: `/api/user/create-booking`,
        UPDATE_PAYMMENT: `/api/user/update-payment`
    },

    // BOOKING
    BOOKING_HISTORY: {
        TICKET_LIST: `/api/user/get-tickets`,
        TICKET: `/api/user/get-ticket`
    }
}
export default API_ENDPOINT;