import React from 'react'
import { IBookingTicketDetails } from '../booking-ticket-details-interfaces';
import { ArrowForwardRounded, ConfirmationNumberOutlined, ModeOfTravelOutlined } from '@mui/icons-material';
import { BookingStatus } from '../../ticket-list/booking-history-interfaces';

interface TicketHeaderProps {
    bookingTicketDetails: IBookingTicketDetails
    button: React.ReactElement
}

const TicketHeader: React.FC<TicketHeaderProps> = ({ bookingTicketDetails, button }) => {
    return (
        <section className="flex flex-wrap justify-between items-center border-b pb-4 mb-6">
            <div className='flex flex-wrap gap-2 items-start lg:justify-end'>
                <div>
                    <h2 className='mb-2'>
                        <ConfirmationNumberOutlined className='mr-3 pb-1.5' fontSize='large' />
                        {bookingTicketDetails.trip.route.ghatFrom.ghatName}
                        <ArrowForwardRounded className='mx-3' />
                        {bookingTicketDetails.trip.route.ghatTo.ghatName}</h2>
                    <h6>PNR:&emsp;241066322&emsp;|&emsp;Booking No:&emsp;241066186</h6>
                </div>

                <div
                    className={`px-3 py-1 rounded-full text-sm justify-end font-semibold mx-4 mt-2.5
                                        ${bookingTicketDetails.bookingStatus === BookingStatus.CONFIRM ?
                            'bg-green-100 text-green-600' : 'bg-yellow-100 text-yellow-600'}`}>
                    {bookingTicketDetails.bookingStatus}
                </div>
            </div>
            <div className='ml-auto mt-4 md:mt-0'>
                {button}
            </div>
        </section>
    )
}

export default TicketHeader;