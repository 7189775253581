import { Container } from '@mui/material';
import React from 'react'

const AboutUs: React.FC = () => {
    return (
        <div className='my-6'>
            <Container>
                {/* About the Portal */}
                <section>
                    <h2 className="text-sky-700 mb-4">About the Portal</h2>
                    <p className="text-slate-600 text-justify">
                        {`Assam Inland Water Transport Development Society's e-Ticketing Portal provides quick and hassle-free 
                            ticket booking services for ferry routes across Assam. Whether you're commuting or exploring, we ensure 
                            a seamless experience for your journey.`}
                    </p>
                </section>
                <hr className='mt-6' />
            </Container>
        </div>
    )
}

export default AboutUs;