import React from 'react'
import { IUser } from './user-interfaces';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableActionButton from '../../../components/buttons/TableActionButton';
import { BorderColor } from '@mui/icons-material';

interface UserTableProps {
    rows: IUser[];
    handleEditClick: (id: number) => Promise<void>;
}

const UserTable: React.FC<UserTableProps> = ({ ...props }) => {

    const cols: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No', flex: 1, renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'fullName', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'mobileNo', headerName: 'Mobile No', flex: 1 },
        { field: 'role', headerName: 'Role', flex: 1 },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: params => {
                return (
                    <TableActionButton
                        type='primary'
                        onClick={() => props.handleEditClick(Number(params.id))}>
                        <BorderColor fontSize='small' />
                    </TableActionButton>
                )
            }
        },
    ];

    return (
        <div className='mt-3 '>
            <div className='flex justify-center'>
                <div className='flex w-full bg-white'>
                    <DataGrid
                        rows={props.rows}
                        columns={cols}
                        autosizeOnMount
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                    />
                </div>
            </div>
        </div>

    )
}

export default UserTable;