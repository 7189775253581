import { Container } from '@mui/material'
import React from 'react'
import Card from '../../../../components/cards/Card'
import { EventAvailable, Lock, NotificationsActive, TouchApp } from '@mui/icons-material'

const Features: React.FC = () => {

    const features = [
        {
            icon: <EventAvailable fontSize='large' />,
            title: 'Ease of Booking',
            description: 'Quickly book tickets for your preferred routes.',
        },
        {
            icon: <NotificationsActive fontSize='large' />,
            title: 'Real-Time Updates',
            description: 'Stay informed with live trip schedules and updates.',
        },
        {
            icon: <TouchApp fontSize='large' />,
            title: 'User-Friendly Design',
            description: 'Navigate effortlessly through a sleek and intuitive interface.',
        },
        {
            icon: <Lock fontSize='large' />,
            title: 'Secure Transactions',
            description: 'Book with confidence with our robust security protocols.',
        },
    ]

    return (
        <div className='my-6'>
            <Container>
                {/* Features */}
                <section>
                    <h2 className="text-sky-700 mb-4">Features</h2>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        {
                            features.map((feature, index) => (
                                <div key={index} className='h-full'>
                                    <Card>
                                        <div className="flex items-center justify-center text-sky-600">
                                            {feature.icon}
                                        </div>
                                        <div className="text-center mt-4">
                                            <h3 className="text-lg font-semibold text-slate-800">{feature.title}</h3>
                                            <p className="text-slate-600 text-sm">{feature.description}</p>
                                        </div>
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                </section>
                <hr className='mt-6' />
            </Container>
        </div>
    )
}

export default Features