import { AxiosResponse } from 'axios';
import React from 'react';
import API_ENDPOINT from '../../../../constants/api-constants';
import { useApiService } from '../../../../hooks/useApiService';
import { useHandleErrorService } from '../../../../hooks/useHandleErrorService';
import { BookingDetails, BookingPeriodFilter } from './booking-history-interfaces';

export default function useBookingHistoryService() {

    const { fetchAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const fetchTicketList = async (
        bookingPeriodFilter: BookingPeriodFilter,
        updateState: React.Dispatch<React.SetStateAction<BookingDetails[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.BOOKING_HISTORY.TICKET_LIST,
                bookingPeriodFilter
            )

            updateState(response.data.object);
            
        } catch (error) {
            handleError(error);
        }
    }

    const fetchTicketDetails = async (bookingId: string) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.BOOKING_HISTORY.TICKET,
                { id: bookingId }
            )

            return response.data.object;
            
        } catch (error) {
            handleError(error);
        }
    }

    return {
        fetchTicketList,
        fetchTicketDetails
    }
}
