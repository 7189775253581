import React, { useEffect, useState } from 'react'
import DefaultButton from '../../../components/buttons/DefaultButton'
import FormControl from '../../../components/forms/FormControl'
import ModalWrapper from '../../../components/modals/ModalWrapper'
import { SelectOption } from '../../../constants/type-constants'
import { useCommonService } from '../../../hooks/useCommonService'
import { Route } from '../route-management/route-interfaces'
import { Trip, TripManagementTableRow, TripPost } from './trip-interfaces'
import { useTripManagementService } from './useTripManagementService'

interface TripManagementModalProps {
    trip: Trip | undefined
    routes: Route[]
    division: string
    divisionOptions: SelectOption[]
    tripModalOpen: boolean
    handleTripModalClose: () => void
    ghatFrom: string
    ghatTo: string
    tripDate: string
    setTripTableRows: React.Dispatch<React.SetStateAction<TripManagementTableRow[]>>
    setTripList: React.Dispatch<React.SetStateAction<Trip[]>>
}

const TripManagementModal: React.FC<TripManagementModalProps> = ({
    trip,
    routes,
    division,
    divisionOptions,
    tripModalOpen,
    handleTripModalClose,
    ghatFrom,
    ghatTo,
    tripDate,
    setTripTableRows,
    setTripList,
}) => {

    const {
        fetchServiceTypeOptions,
        fetchVesselOptionsByDivision,
        fetchStatusOptions,
        fetchBookingModeOptions,
        fetchRoutesByDivision,
    } = useCommonService();

    const {
        handleInputChange,
        generateGhatFromOptions,
        generateGhatToOptions,
        fetchTrips,
        saveTrip
    } = useTripManagementService();

    const [modalRoutes, setModalRoutes] = useState<Route[]>([]);

    const [serviceTypeOptions, setServiceTypeOptions] = useState<SelectOption[]>([]);
    const [vesselOptions, setVesselOptions] = useState<SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);
    const [bookingModeOptions, setBookingModeOptions] = useState<SelectOption[]>([]);
    const [formGhatFromOptions, setFormGhatFromOptions] = useState<SelectOption[]>([]);
    const [formGhatToOptions, setFormGhatToOptions] = useState<SelectOption[]>([]);

    const [tripId, setTripId] = useState('');
    const [formDivision, setFormDivision] = useState('');
    const [formGhatFrom, setFormGhatFrom] = useState('');
    const [formGhatTo, setFormGhatTo] = useState('');
    const [formTripDate, setFormTripDate] = useState('');
    const [vessel, setVessel] = useState('');
    const [departureHour, setDepartureHour] = useState('');
    const [departureMin, setDepartureMin] = useState('');
    const [departureSec, setDepartureSec] = useState('');
    const [tripDurationHour, setTripDurationHour] = useState('');
    const [tripDurationMin, setTripDurationMin] = useState('');
    const [tripDurationSec, setTripDurationSec] = useState('');
    const [tripStatus, setTripStatus] = useState('');
    const [dockNo, setDockNo] = useState('');
    const [bookingMode, setBookingMode] = useState('');
    const [activateBefore, setActivateBefore] = useState('');
    const [onlineSeat, setOnlineSeat] = useState('');
    const [offlineSeat, setOfflineSeat] = useState('');
    const [serviceType, setServiceType] = useState('');

    useEffect(() => {
        setFormDivision(division);
    }, [division, tripModalOpen]);

    useEffect(() => {
        setModalRoutes(routes);
    }, [routes])

    useEffect(() => {
        if (formDivision && formDivision !== '') {
            fetchRoutesByDivision(formDivision, setModalRoutes);
        }
    }, [formDivision]);

    useEffect(() => {
        if (formDivision != '') {
            fetchStatusOptions(setStatusOptions, setTripStatus);
            fetchBookingModeOptions(setBookingModeOptions, setBookingMode);
            fetchServiceTypeOptions(setServiceTypeOptions, setServiceType);
            fetchVesselOptionsByDivision(formDivision, setVesselOptions, setVessel);
        }
    }, [formDivision]);

    useEffect(() => {
        if (modalRoutes.length) {
            generateGhatFromOptions(modalRoutes, setFormGhatFromOptions, setFormGhatFrom);
        }
    }, [modalRoutes]);

    useEffect(() => {
        if (formGhatFrom != '') {
            generateGhatToOptions(modalRoutes, formGhatFrom, setFormGhatToOptions, setFormGhatTo);
        }
    }, [formGhatFrom]);

    useEffect(() => {
        const [depH = '', depM = '', depS = ''] = trip?.departureTime?.split(':') || [];
        const [durH = '', durM = '', durS = ''] = trip?.tripDuration?.split(':') || [];

        setTripId(trip?.id || '');
        setFormGhatFrom(trip?.route?.ghatFrom?.id || '');
        setFormGhatTo(trip?.route?.ghatTo?.id || '');
        setServiceType(trip?.serviceType || serviceType);
        setFormTripDate(trip?.tripDate || '');
        setVessel(trip?.vessel?.id || '');
        setDepartureHour(depH);
        setDepartureMin(depM);
        setDepartureSec(depS);
        setTripDurationHour(durH);
        setTripDurationMin(durM);
        setTripDurationSec(durS);
        setTripStatus(trip?.tripStatus || '');
        setDockNo(trip?.dockNo || '');
        setBookingMode(trip?.bookingMode || bookingMode);
        setActivateBefore(trip?.activateBefore || '');
        setOnlineSeat(trip?.onlineSeat || '');
        setOfflineSeat(trip?.offlineSeat || '');
    }, [trip]);


    const handleTripSaveClick = async () => {

        const tripRoute = modalRoutes.find((route: Route) => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);

        console.log(routes);
        

        const postData: TripPost = {
            id: trip?.id || null,
            route: {
                id: tripRoute?.id || '',
            },
            vessel: {
                id: vessel,
            },
            serviceType: serviceType,
            onlineSeat: onlineSeat,
            offlineSeat: offlineSeat,
            tripDate: formTripDate,
            departureTime: `${departureHour}:${departureMin}:${departureSec}`,
            tripDuration: `${tripDurationHour}:${tripDurationMin}:${tripDurationSec}`,
            activateBefore: activateBefore,
            dockNo: dockNo,
            bookingMode: bookingMode,
            tripStatus: tripStatus,
        }

        const saved = await saveTrip(postData);

        if (saved) {
            fetchTrips(
                routes,
                ghatFrom,
                ghatTo,
                tripDate,
                setTripTableRows,
                setTripList
            );
        }

    }

    return (
        <ModalWrapper
            open={tripModalOpen}
            handleClose={handleTripModalClose}
            title={'Trip Details'}>
            <div>
                <form className='grid grid-cols-3 gap-y-4 gap-x-2'>
                    <input type="text" value={tripId} readOnly hidden />
                    {
                        (!trip?.id || trip.id == '') &&
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Division'
                                type={'select'}
                                id={'formDivision'}
                                value={formDivision}
                                onChange={handleInputChange(setFormDivision)}
                                options={divisionOptions}
                            />
                        </div>}
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='From'
                            type={'select'}
                            id={'formGhatFrom'}
                            value={formGhatFrom}
                            onChange={handleInputChange(setFormGhatFrom)}
                            options={formGhatFromOptions}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='To'
                            type={'select'}
                            id={'formGhatTo'}
                            value={formGhatTo}
                            onChange={handleInputChange(setFormGhatTo)}
                            options={formGhatToOptions}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Trip Date'
                            type={'date'}
                            id={'formTripDate'}
                            value={formTripDate}
                            onChange={handleInputChange(setFormTripDate)}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Vessel'
                            type={'select'}
                            id={'formVessel'}
                            value={vessel}
                            options={vesselOptions}
                            onChange={handleInputChange(setVessel)}
                        />
                    </div>
                    <div>
                        <label
                            className='block mb-2 text-sm font-medium text-gray-900'
                            htmlFor='departureHour'
                        >
                            Departure Time
                        </label>
                        <div className='flex'>
                            <div className='w-1/3 pr-2'>
                                <FormControl
                                    mandatoryField={true}
                                    type={'number'}
                                    id={'departureHour'}
                                    placeholder='HH'
                                    value={departureHour}
                                    onChange={handleInputChange(setDepartureHour)}
                                />
                            </div>
                            <div className='w-1/3 pr-2'>
                                <FormControl
                                    mandatoryField={true}
                                    type={'number'}
                                    id={'departureMin'}
                                    placeholder='MM'
                                    value={departureMin}
                                    onChange={handleInputChange(setDepartureMin)}
                                />
                            </div>
                            <div className='w-1/3'>
                                <FormControl
                                    mandatoryField={true}
                                    type={'number'}
                                    id={'departureSec'}
                                    placeholder='SS'
                                    value={departureSec}
                                    onChange={handleInputChange(setDepartureSec)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label
                            className='block mb-2 text-sm font-medium text-gray-900'
                            htmlFor='durationHour'
                        >
                            Duration
                        </label>
                        <div className='flex'>
                            <div className='w-1/3 pr-2'>
                                <FormControl
                                    mandatoryField={true}
                                    type={'number'}
                                    id={'durationHour'}
                                    placeholder='HH'
                                    value={tripDurationHour}
                                    onChange={handleInputChange(setTripDurationHour)}
                                />
                            </div>
                            <div className='w-1/3 pr-2'>
                                <FormControl
                                    mandatoryField={true}
                                    type={'number'}
                                    id={'durationMin'}
                                    placeholder='MM'
                                    value={tripDurationMin}
                                    onChange={handleInputChange(setTripDurationMin)}
                                />
                            </div>
                            <div className='w-1/3'>
                                <FormControl
                                    mandatoryField={true}
                                    type={'number'}
                                    id={'durationSec'}
                                    placeholder='SS'
                                    value={tripDurationSec}
                                    onChange={handleInputChange(setTripDurationSec)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Service Type'
                            type={'select'}
                            id={'serviceType'}
                            value={serviceType}
                            options={serviceTypeOptions}
                            onChange={handleInputChange(setServiceType)}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Status'
                            type={'select'}
                            id={'tripStatus'}
                            value={tripStatus}
                            options={statusOptions}
                            onChange={handleInputChange(setTripStatus)}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Dock No'
                            type={'text'}
                            id={'dockNo'}
                            value={dockNo}
                            onChange={handleInputChange(setDockNo)}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Booking Mode'
                            type={'select'}
                            id={'bookingMode'}
                            value={bookingMode}
                            options={bookingModeOptions}
                            onChange={handleInputChange(setBookingMode)}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Online Seat'
                            type={'number'}
                            id={'onlineSeat'}
                            value={onlineSeat}
                            onChange={handleInputChange(setOnlineSeat)}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Offline Seat'
                            type={'number'}
                            id={'offlineSeat'}
                            value={offlineSeat}
                            onChange={handleInputChange(setOfflineSeat)}
                        />
                    </div>
                    <div>
                        <FormControl
                            mandatoryField={true}
                            label='Activate Before (hours)'
                            type={'number'}
                            id={'activateBefore'}
                            value={activateBefore}
                            onChange={handleInputChange(setActivateBefore)}
                        />
                    </div>
                </form>
                <div className='w-full mt-4'>
                    <DefaultButton
                        onClick={handleTripSaveClick}
                        buttonText={'Save Trip Details'}
                    />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default TripManagementModal