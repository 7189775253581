import React, { ReactNode } from "react";
import DefaultFooter from "../footers/DefaultFooter";
import DefaultHeader from "../headers/DefaultHeader";
import { Container } from "@mui/material";

interface BodyProps {
    children: ReactNode;
}

const Body: React.FC<BodyProps> = ({ children }) => {
    return (
        <div className="bg-slate-50">
            <div className="main-content min-h-screen pb-6">
                <DefaultHeader />
                <div className="mt-4">
                    <Container>
                        {children}
                    </Container>
                </div>
            </div>
            <DefaultFooter />
        </div>
    );
}

export default Body;