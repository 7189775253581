import React from 'react';
import Body from '../../../components/body/Body';
import { IUser, UserFormInitialState } from './user-interfaces';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import UserForm from './UserForm';
import UserTable from './UserTable';
import DefaultButton from '../../../components/buttons/DefaultButton';
import { AccountCircle, AddRounded, DeviceHub } from '@mui/icons-material';
import { useUserManagementService } from './useUserManagementSevice';

const UserManagement: React.FC = () => {

    const { saveUser } = useUserManagementService();

    const [userList, setUserList] = React.useState<IUser[]>([]);
    const [userForm, setUserForm] = React.useState<IUser>(UserFormInitialState);

    const [userDetailsModalOpen, setUserDetailsModalOpen] = React.useState(false);
    const handleUserDetailsModalOpen = () => setUserDetailsModalOpen(true);
    const handleUserDetailsModalClose = () => setUserDetailsModalOpen(false);

    const handleEditClick = async (id: number) => {
        const user = userList.find(user => user.id === id);
        user && setUserForm(user);
        handleUserDetailsModalOpen();
    }

    const handleAddUserClick = () => {
        setUserForm(UserFormInitialState);
        handleUserDetailsModalOpen();
    }

    const handleSaveUserClick = () => saveUser(userForm);

    return (
        <Body>

            <div className='flex flex-wrap justify-between items-center'>
                <h3 className='flex justify-center items-center gap-4'><AccountCircle /> User Management</h3>
            </div>

            <hr />

            <div className='grid grid-cols-1 lg:grid-cols-2 items-end mt-2'>
                <div className='flex w-max'>
                    <h4>User List</h4>
                </div>
                <div className='flex w-max ml-auto'>
                    <PrimaryButton
                        onClick={handleAddUserClick}
                        buttonText='Create User'
                        buttonIcon={AddRounded}
                    />
                </div>
            </div>

            <UserTable
                rows={userList}
                handleEditClick={handleEditClick}
            />

            <ModalWrapper
                open={userDetailsModalOpen}
                handleClose={handleUserDetailsModalClose}
                title={'User Details'}
            >
                <UserForm
                    userForm={userForm}
                    setUserForm={setUserForm}
                />
                <PrimaryButton
                    fullWidth={true}
                    buttonText={'Save User'}
                    onClick={handleSaveUserClick}
                />
            </ModalWrapper>
        </Body>
    )
}

export default UserManagement