import React from 'react'
import { IBookingTicketDetails } from '../booking-ticket-details-interfaces';

interface PaymentInfoProps {
    bookingTicketDetails: IBookingTicketDetails
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({ bookingTicketDetails }) => {
    return (
        <section className="grid grid-cols-1 lg:grid-cols-3 gap-4 border-b pb-6 mb-6">
            <div className="flex">
                <table className='w-full'><tbody className='font-medium'>
                    <tr><td className='pr-8'>Email:</td><td className='text-right md:text-start'>{bookingTicketDetails.email}</td></tr>
                    <tr><td className='pr-8'>Mobile No:</td><td className='text-right md:text-start'>{bookingTicketDetails.mobileNo}</td></tr>
                    <tr><td className='h-7'></td><td></td></tr>
                </tbody></table>
            </div>
            <div className='flex md:justify-center'>
                <table className='w-full'><tbody className='font-medium'>
                    <tr><td>Order ID:&emsp;</td><td className='text-right md:text-start'>{bookingTicketDetails.payment.orderId}</td></tr>
                    <tr><td>Payment Status:&emsp;</td><td className='text-right md:text-start'>{bookingTicketDetails.payment.paymentStatus}</td></tr>
                    <tr><td>Payment Mode:&emsp;</td><td className='text-right md:text-start'>{bookingTicketDetails.payment.paymentMode}</td></tr>
                </tbody></table>
            </div>
            <div className="flex md:justify-end">
                <table className='w-full'><tbody className='font-medium'>
                    <tr><td className='pr-8'>Gross Amount:</td><td className='text-right'>{bookingTicketDetails.payment.grossAmount}</td></tr>
                    <tr><td className='pr-8'>Convenience Fee:</td><td className='text-right'>{bookingTicketDetails.payment.convenienceFee}</td></tr>
                    <tr><td className='pr-8'>Total Fare:</td><td className='text-right text-2xl font-semibold'>{bookingTicketDetails.payment.netAmount}</td></tr>
                </tbody></table>
            </div>
        </section>
    )
}

export default PaymentInfo;