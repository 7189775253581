import React from 'react'
import Card from '../../../../components/cards/Card'
import { Container } from '@mui/material'
import { DateRange, LocationOn, Search } from '@mui/icons-material'

const HowItWorks: React.FC = () => {

    const steps = [
        { icon: <LocationOn className='mb-1 mr-2' />, title: 'Choose your origin and destination.' },
        { icon: <DateRange className='mb-1 mr-2' />, title: 'Select your travel date.' },
        { icon: <Search className='mb-1 mr-2' />, title: 'Browse available trips and book your tickets.' },
    ]

    return (
        <div className='my-6'>
            <Container>
                {/* How It Works */}
                <section>
                    <h2 className="text-sky-700">How It Works</h2>
                    <p className='mb-6 text-justify'>
                        {`Booking your tickets is now simpler than ever. With just three easy steps, you can plan your journey, 
                        choose your travel date, and secure your tickets—all in a matter of minutes. Start your hassle-free 
                        booking experience today!`}
                    </p>
                    <Card>
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
                            {
                                steps.map((step, index) => (
                                    <div key={index} className='text-center border border-dashed text-lg border-slate-300 rounded-lg p-4 text-slate-500'>
                                        <div>
                                            {step.icon} {step.title}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Card>
                </section>
            </Container>
        </div>
    )
}

export default HowItWorks