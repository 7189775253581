import React from 'react';
import { useEffect } from 'react';
import MapIcon from '@mui/icons-material/Map';
import RouteIcon from '@mui/icons-material/Route';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import Body from '../../components/body/Body';
import { ROUTES } from '../../constants/routes-constants';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const adminLinks = [
    { name: 'Ghat Management', href: ROUTES.MANAGE.GHAT, icon: MapIcon },
    { name: 'Route Management', href: ROUTES.MANAGE.ROUTE, icon: RouteIcon },
    { name: 'Trip Management', href: ROUTES.MANAGE.TRIP, icon: ModeOfTravelIcon },
    { name: 'Vessel Management', href: ROUTES.MANAGE.VESSEL, icon: DirectionsBoatIcon },
]

const DivHeadDashboard: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Body>
            <div>
                <div className='mx-auto max-w-screen-xl items-center px-6 py-1 lg:px-6 mt-2'>
                    <h1 className="text-3xl font-medium">Division Head - Dashboard</h1>
                </div>

                <div className="mx-auto max-w-screen-xl items-center px-6 py-1 lg:px-6 mt-2">
                    <div className="bg-white shadow-md my-3 rounded-lg">
                        <div className="p-4 flex gap-3">
                            {adminLinks.map((item) => (
                                <div key={item.name}
                                    className="w-1/4 group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-sky-50 bg-gray-50 border-2 border-blue-950 border-opacity-5">
                                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" />
                                    </div>
                                    <div className="flex-auto">
                                        <button onClick={() => navigate(item.href)} className="block font-semibold text-gray-900">
                                            {item.name}
                                            <span className="absolute inset-0" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <PrimaryButton
                    buttonText="Animated Text"
                    onClick={() => navigate(ROUTES.TEST.ANIMATED_TEXT)}
                />
            </div>
        </Body>
    )
}

export default DivHeadDashboard
